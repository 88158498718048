import React from 'react'
import './Header.scss'
import logo from '../../assets/images/logo-header.svg'
import Navbar from '../Navbar/Navbar'
import { AuthContext } from '../../context/Auth'
import { Breadcrumb, Menu, Dropdown } from 'antd'
import { useHistory } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'

function Header() {
  const history = useHistory()
  const { userInfo } = React.useContext(AuthContext)
  const logout = () => {
    localStorage.clear()
    history.push('/login')
  }

  const menu = (
    <Menu>
      <Menu.Item danger onClick={() => logout()}>
        Выйти
      </Menu.Item>
    </Menu>
  )
  return (
    <div className="header">
      <div className="container">
        <div className="header__container">
          <div className="nav__wrapper">
            <div className="logo__block">
              <img src={logo} alt="logo-sfedu" />
            </div>
            <div className="nav__block">
              <Navbar />
            </div>
          </div>
          <div className="user__wrapper">
            <Dropdown overlay={menu}>
              <a className="" onClick={(e) => e.preventDefault()}>
                {userInfo ? userInfo : null}&nbsp;
                <DownOutlined />
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
