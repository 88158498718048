import React, { useState } from 'react'
import { Typography, Space, Input, Button, Select, Radio, Form } from 'antd'
import axios from 'axios'
import classes from './Registration.module.css'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import logo from '../../assets/images/logo.svg'

const { Text } = Typography
const { Option } = Select

function RegistrationForm(props) {
  const history = useHistory()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)

  const handleSubmit = data => {
    setLoading(true)
    data.username = data.email
    data.plainPassword = data.password1
    data.fullName = `${data.lastName} ${data.firstName} ${data.secondName}`
    axios
      .post('/api/users', {
        username: data.username,
        fullName: data.fullName,
        email: data.email,
        post: data.post,
        university: data.university,
        plainPassword: data.password1,
      })
      .then(response => {
        addToast('Регистрация прошла успешно!', { appearance: 'success' })
        history.push('/login')
        setLoading(false)
      })
      .catch(e => {
        const error = JSON.stringify(e.response)
        console.log('my errr', error)
        setLoading(false)
        if (
          error.includes('email') &&
          error.includes('Это значение уже используется')
        ) {
          addToast('Такой email уже используется', { appearance: 'error' })
        }
        if (
          error.includes('username') &&
          error.includes('Это значение уже используется')
        ) {
          addToast('Такое имя уже используется', { appearance: 'error' })
        }
        addToast('Ошибка сервера. Попробуйте еще раз!', { appearance: 'error' })
      })
  }

  return (
    <Form
      className={classes.regForm}
      onFinish={handleSubmit}
      initialValues={{ gender: 'male' }}
    >
      <Space align="center" direction="vertical">
        <img src={logo} alt="logo" className={classes.logo} />
        <div className={classes.main__wrapper}>
          <div className={classes.column__block}>
            <div className={classes.field_left}>
              <Text className={classes.label} type="secondary">
                Фамилия
              </Text>
            </div>
            <Form.Item
              name="lastName"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input id="lastName" name="lastName" className={classes.input} />
            </Form.Item>
            <div className={classes.field_left}>
              <Text type="secondary" className={classes.label}>
                Отчество
              </Text>
            </div>
            <Form.Item
              name="secondName"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input
                id="secondName"
                name="secondName"
                className={classes.input}
              />
            </Form.Item>
            <div className={classes.field_left}>
              <Text type="secondary" className={classes.label}>
                Учебное заведение
              </Text>
            </div>
            <Form.Item
              name="university"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input
                id="university"
                name="university"
                className={classes.input}
              />
            </Form.Item>
            <div className={classes.field_line}>
              <Text
                style={{ marginRight: '30px' }}
                className={classes.label}
                type="secondary"
              >
                Пол
              </Text>
              <Form.Item
                name="gender"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Radio.Group defaultValue="male">
                  <Radio value={'male'}>Мужчина</Radio>
                  <Radio value={'female'}>Женщина</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
          <div className={classes.column__wrapper}>
            <div className={classes.field_left}>
              <Text className={classes.label} type="secondary">
                Имя
              </Text>
            </div>
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input
                id="firstName"
                name="firstName"
                className={classes.input}
              />
            </Form.Item>
            <div className={classes.field_left}>
              <Text type="secondary" className={classes.label}>
                E-mail
              </Text>
            </div>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Неправильный E-mail!',
                },
              ]}
            >
              <Input id="email" name="email" className={classes.input} />
            </Form.Item>
            <div className={classes.field__line}>
              <Text type="secondary" className={classes.label}>
                Кем вы являетесь?
              </Text>
            </div>
            <Form.Item
              name="post"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Кем вы являетесь?"
                optionFilterProp="children"
                className={classes.select}
                // onChange={onChange}
                // onFocus={onFocus}
                // onBlur={onBlur}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="Студент">Студент</Option>
                <Option value="Преподаватель">Преподаватель</Option>
                <Option value="Другое">Другое</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className={classes.main__wrapper}>
          <div className={classes.column__block}>
            <div className={classes.field_left}>
              <Text type="secondary" className={classes.label}>
                Пароль
              </Text>
            </div>
            <Form.Item
              name="password1"
              rules={[
                {
                  validator: async (_, password1) => {
                    if (!password1 || password1.length < 8) {
                      return Promise.reject(
                        new Error('Пароль должен содержать более 8 знаков')
                      )
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <Input
                id="password1"
                name="password1"
                type="password"
                className={classes.input}
              />
            </Form.Item>
          </div>
          <div className={classes.column__wrapper}>
            <div className={classes.field_left}>
              <Text type="secondary" className={classes.label}>
                Повторите пароль
              </Text>
            </div>
            <Form.Item
              name="password2"
              rules={[
                ({ getFieldValue }) => ({
                  validator: async (_, password2) => {
                    if (
                      !password2 ||
                      getFieldValue('password1') === password2
                    ) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('Пароли не совпадают!'))
                  },
                }),
              ]}
            >
              <Input
                id="password2"
                type="password"
                name="password2"
                className={classes.input}
              />
            </Form.Item>
          </div>
        </div>
        <Button
          className={classes.submit}
          type="primary"
          htmlType="submit"
          disabled={loading}
        >
          Готово
        </Button>
      </Space>
      <Space align="center" direction="vertical">
        <Button
          disabled={loading}
          type="primary"
          className={classes.back}
          onClick={() => history.push('login')}
        >
          Назад
        </Button>
      </Space>
    </Form>
  )
}

export default RegistrationForm
