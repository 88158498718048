import React from 'react'
import ModalLayout from '../ModalLayout'
import '../ModalItem.scss'
import { Button } from 'antd'
import CustomModalSelect from '../../../components/Custom/CustomSelect'
import Title from '../../../components/labels/Title'
import Subtitle from '../../../components/labels/Subtitle'

import {
  DROPDOWN_KEYS,
  DIRECTION_KEYS,
  SUBDIVISION_KEYS,
  STATUS_KEY,
} from '../../../constants/constans'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { DatePicker } from 'antd'

const { RangePicker } = DatePicker

function FiltersModal({
  closeModal,
  handleChangeSelect,
  filterSubdivision,
  filterDivision,
  filterStatus,
}) {
  return (
    <div>
      <ModalLayout isOpen={true} onClose={closeModal}>
        <div className="modal__wrapper">
          <div className="modal__wrapper_container">
            <div className="modal__filters_title">
              <Title name={'Фильтры'} />
            </div>
            <div>
              <Subtitle name={'Период'} />
              <RangePicker className="date__element" locale={locale} />
            </div>
            <div>
              <CustomModalSelect
                className="customModalSelect"
                handleChangeSelect={handleChangeSelect}
                defaultValue={filterDivision}
                type={'direction'}
                options={DIRECTION_KEYS}
                selectKey={DROPDOWN_KEYS[1]}
              />
            </div>
            <div>
              <CustomModalSelect
                handleChangeSelect={handleChangeSelect}
                type={'subdivision'}
                defaultValue={filterSubdivision}
                options={SUBDIVISION_KEYS}
                selectKey={DROPDOWN_KEYS[2]}
              />
            </div>
            <Button onClick={closeModal} className="modal__filters_button">
              Готово
            </Button>
          </div>
        </div>
      </ModalLayout>
    </div>
  )
}

export default FiltersModal
