import "../../Variable.scss"
import Header from "../../components/Header/Header"

function MainLayout({ children }) {
  return (
    <>
      <div className="app_wrapper">
        <Header />
        <div className="app_wrapper__content">{children}</div>
      </div>
    </>
  )
}

export default MainLayout
