import React from 'react'
import '../../Variable.scss'

function Suptitle({name}) {
    return (
        <>
            <div className="main__subtitle_el">{name}</div>
        </>
    )
}

export default Suptitle
