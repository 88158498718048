import React from 'react'
import ModalLayout from '../ModalLayout'
import '../ModalItem.scss'
import registrationEventImage from '../../../assets/images/review.svg'
import { Button } from 'antd'

function UnderConsiderationModal({ closeModal, eventId }) {
  return (
    <div>
      <ModalLayout isOpen={true} onClose={closeModal}>
        <div className="modal__wrapper">
          <div className="modal__wrapper_container">
            <div className="modal__picture">
              <img src={registrationEventImage} />
            </div>
            <div className="modal__title">
              Вашу кандидатуру рассмотрят в ближайшее время
            </div>
            <div className="modal__button_block">
              <Button onClick={closeModal} className="modal__button">
                Готово
              </Button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </div>
  )
}

export default UnderConsiderationModal
