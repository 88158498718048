import React from 'react'
import { Tag, Button, Space } from 'antd'
import icon from '../../assets/images/icon_dow.svg'
import { DownloadOutlined } from '@ant-design/icons'

const DocumentsComponent = ({ docs }) => {
  return (
    <Space
      align='start'
      direction='horizontal'
      wrap
      style={{ width: '100%', marginTop: '20px', marginBottom: '40px' }}
    >
      {docs.map((i) => (
        <Tag
          color='blue'
          icon={<DownloadOutlined />}
          style={{
            height: '30px',
            textAlign: 'center',
            fontSize: '17px',
            padding: '3px',
          }}
          key={i.name}
          value={i.name}
        >
          <a
            href={`${process.env.REACT_APP_BASE_PATH}/uploads/${i?.path}`}
            target='_blank'
          >
            {i.name}
          </a>
        </Tag>
      ))}
    </Space>
  )
}

export default DocumentsComponent
