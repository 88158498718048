import { logout } from './auth'
import request from './request'

export const eventsApi = {
  getEvents(params) {
    return request({
      url: `/api/events`,
      params: {
        deleted: false,
        ...params,
      },
    })
  },
  getMyEvents(params) {
    const userId = localStorage['userId']
    if (userId) {
      return request({
        url: `/api/event_user_candidates`,
        params: {
          'user.id': localStorage.getItem('userId'),
          limit: 999,
          deleted: false,
          ...params,
        },
      })
    } else {
      logout()
    }
    return false
  },
  getMyReviews(params) {
    const userId = localStorage['userId']
    if (userId) {
      return request({
        url: `/api/event_feedbacks`,
        params: {
          ...params,
          'user.id': userId,
        },
      })
    } else {
      logout()
    }
    return false
  },
  getEvent(id) {
    return request({
      url: `/api/events/${id}`,
      method: 'get',
    })
  },
  takeParticipate(data) {
    return request({
      url: `/api/event_user_candidates`,
      method: 'post',
      data,
    })
  },
  cancelParticipate(id) {
    return request({
      url: `/api/events/${id}/unparticipate`,
      method: 'get',
    })
  },
  sendReview(data) {
    return request({
      url: `/api/event_feedbacks`,
      method: 'post',
      data,
    })
  },
  followEvent(id) {
    return request({
      url: `/api/events/${id}/subscribe`,
      method: 'GET',
    })
  },
  cancelFollow(id) {
    return request({
      url: `/api/events/${id}/unsubscribe`,
      method: 'GET',
    })
  },
}
