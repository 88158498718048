import React, { useState, useContext } from 'react'
import { Button } from 'antd'
import { AuthContext } from '../../context/Auth'
import { eventsApi } from '../../api/api'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { useToasts } from 'react-toast-notifications'
dayjs.extend(isBetween)

function CustomButtonGroup({
  modalIsOpen,
  modalAcceptIsOpen,
  loading,
  event,
  setLoading,
}) {
  const {
    userEvents,
    userCancelledEvents,
    userModeratedEvents,
    userFollowedEvents,
    userReviews,
    saveUserEvents,
    saveUserModeratedEvents,
    saveUserFollowedEvents,
    userFaculty,
    handleRemoveFollowingEvent,
    handleRemoveParticipateEvent,
    handleRemoveParticipateModeratedEvent,
  } = useContext(AuthContext)
  const { addToast } = useToasts()

  const cancellButtonEvent = e => {
    e.persist()
    e.stopPropagation()
    e.preventDefault()
  }

  const takeParticipate = async e => {
    e.persist()
    e.stopPropagation()
    e.preventDefault()
    setLoading(true)
    try {
      await eventsApi.takeParticipate({
        event: `api/events/${event.id}`,
      })
      if (event.isModerationPossible) {
        saveUserModeratedEvents(event.id)
        addToast('Вашу кандидатуру рассмотрят в ближайшее время', {
          appearance: 'moderation',
        })
        setLoading(false)
      } else {
        saveUserEvents(event.id)
        addToast('Вы зарегистрированы на мероприятие', {
          appearance: 'participate',
        })
        setLoading(false)
      }
    } catch (e) {
      addToast('Ошибка при записи на мероприятие', { appearance: 'error' })
      setLoading(false)
    }
  }

  const cancelFollow = async e => {
    e.persist()
    e.stopPropagation()
    e.preventDefault()
    setLoading(true)
    try {
      await eventsApi.cancelFollow(event.id)
      handleRemoveFollowingEvent(event.id)
      addToast('Вы отписались от уведомлений', { appearance: 'unsubscribe' })
      setLoading(false)
    } catch (e) {
      addToast('Ошибка при отписке от уведомлений', { appearance: 'error' })
      setLoading(false)
    }
  }

  const cancelParticipate = async e => {
    e.persist()
    e.stopPropagation()
    e.preventDefault()
    setLoading(true)
    try {
      await eventsApi.cancelParticipate(event.id)
      if (event.isModerationPossible) {
        handleRemoveParticipateModeratedEvent(event.id)
        addToast('Ваша заявка отозвана', {
          appearance: 'leaveEvent',
        })
        setLoading(false)
      } else {
        handleRemoveParticipateEvent(event.id)
        addToast('Ваша заявка отозвана', {
          appearance: 'leaveEvent',
        })
        setLoading(false)
      }
    } catch (e) {
      addToast('Ошибка сервера', { appearance: 'error' })
      setLoading(false)
    }
  }

  const followEvent = async e => {
    e.persist()
    e.stopPropagation()
    e.preventDefault()
    setLoading(true)
    try {
      await eventsApi.followEvent(event.id)
      saveUserFollowedEvents(event.id)
      addToast('Вы подписались на уведомления', { appearance: 'subscribe' })
      setLoading(false)
    } catch (e) {
      addToast('Ошибка при подписке на мероприятие', { appearance: 'error' })
      setLoading(false)
    }
  }

  const isRegistrationPeriod = () => {
    return dayjs(new Date()).isBetween(
      new Date(event.registrationDateStart),
      new Date(event.registrationDateEnd),
      null,
      '[]'
    )
  }

  const isEventDay = () => {
    return dayjs(new Date()).isBetween(
      new Date(event.dateStart),
      new Date(event.dateEnd),
      null,
      '[]'
    )
  }

  const isEventOver = () => {
    return dayjs(new Date()) > dayjs(new Date(event.dateEnd))
  }

  const isUserFaculty = studentFaculty => {
    return event.facultyLimits.some(el => el.faculty === studentFaculty)
  }

  const isEmptySeats = studentFaculty => {
    if (
      !event.facultyLimits?.find(el => el.faculty === studentFaculty).withLimits
    ) {
      return true
    } else if (
      event.facultyLimits?.find(el => el.faculty === studentFaculty)
        .emptySeats > 0
    ) {
      return true
    } else {
      return false
    }
  }

  const isParticipant = () => {
    return (
      userEvents?.includes(event.id) ||
      userCancelledEvents?.includes(event.id) ||
      userModeratedEvents?.includes(event.id)
    )
  }

  const renderButtons = React.useCallback(() => {
    let studentFaculty = userFaculty
    // Если студент не участник
    if (!isParticipant()) {
      //если статус мероприятие активное
      if (event.status === 2) {
        //если идет регистрация
        if (isRegistrationPeriod()) {
          //если есть ограничения
          if (event.withLimits) {
            //если есть ограничения по факультетам
            if (event.withFacultyLimits) {
              // если факультет студента входит в ограничения и есть свободные места
              if (
                isUserFaculty(studentFaculty) &&
                isEmptySeats(studentFaculty)
              ) {
                return (
                  <Button
                    onClick={modalAcceptIsOpen}
                    className="accept"
                    block
                    loading={loading}
                  >
                    Принять участие
                  </Button>
                )
              } else {
                return (
                  <Button
                    className="rejected"
                    block
                    onClick={cancellButtonEvent}
                  >
                    Нет мест
                  </Button>
                )
              }
              // если без ограничений по факультетам
            } else {
              //если ограничения = 0 значит без ограничений
              if (event.candidateLimit === 0) {
                return (
                  <Button
                    onClick={modalAcceptIsOpen}
                    className="accept"
                    block
                    loading={loading}
                  >
                    Принять участие
                  </Button>
                )
              } else {
                //если есть свободные места
                if (event.emptySeats > 0) {
                  return (
                    <Button
                      onClick={modalAcceptIsOpen}
                      className="accept"
                      block
                      loading={loading}
                    >
                      Принять участие
                    </Button>
                  )
                } else {
                  return (
                    <Button
                      className="rejected"
                      block
                      onClick={cancellButtonEvent}
                    >
                      Нет мест
                    </Button>
                  )
                }
              }
            }
            // если нет ограничений
          } else {
            return (
              <>
                <div className="buttons__wrapper">
                  <div className="buttons__item">
                    <Button
                      onClick={modalAcceptIsOpen}
                      className="accept"
                      block
                      loading={loading}
                    >
                      Принять участие
                    </Button>
                  </div>
                  <div className="buttons__item">
                    {!userFollowedEvents?.includes(event.id) ? (
                      <Button
                        onClick={followEvent}
                        className="follow"
                        loading={loading}
                      >
                        Следить
                      </Button>
                    ) : (
                      <Button
                        className="follow isFollowed"
                        onClick={cancelFollow}
                        loading={loading}
                      >
                        Вы подписаны
                      </Button>
                    )}
                  </div>
                </div>
              </>
            )
          }
          //если день мероприятия
        } else if (isEventDay()) {
          return (
            <Button
              className="participant"
              style={{ opacity: 0 }}
              onClick={cancellButtonEvent}
            >
              12312
            </Button>
          )
        } else if (isEventOver()) {
          return (
            <Button className="rejected" onClick={cancellButtonEvent}>
              Закрыто
            </Button>
          )
          //любой другой день
        } else {
          return !userFollowedEvents?.includes(event.id) ? (
            <Button onClick={followEvent} className="follow" loading={loading}>
              Следить
            </Button>
          ) : (
            <Button
              className="follow isFollowed"
              onClick={cancelFollow}
              loading={loading}
            >
              Вы подписаны
            </Button>
          )
        }
      }
      if (event.status === 3) {
        return (
          <Button className="rejected" onClick={cancellButtonEvent}>
            Закрыто
          </Button>
        )
      }
      if (event.status === 4) {
        return (
          <Button className="rejected" onClick={cancellButtonEvent}>
            Отменено
          </Button>
        )
      }
      if (event.status === 5) {
        return !userFollowedEvents?.includes(event.id) ? (
          <Button onClick={followEvent} className="follow" loading={loading}>
            Следить
          </Button>
        ) : (
          <Button
            className="follow isFollowed"
            onClick={cancelFollow}
            loading={loading}
          >
            Вы подписаны
          </Button>
        )
      }
      // юзер участник
    } else {
      // мероприятие активное
      if (event.status === 2) {
        //период регистрации
        if (isRegistrationPeriod()) {
          return (
            <>
              <div className="buttons__wrapper">
                <div className="buttons__item">
                  {userEvents?.includes(event.id) && (
                    <Button
                      className="participant"
                      onClick={cancelParticipate}
                      loading={loading}
                    >
                      Вы участник
                    </Button>
                  )}
                  {userModeratedEvents?.includes(event.id) && (
                    <Button
                      className="participant"
                      onClick={cancelParticipate}
                      loading={loading}
                    >
                      Вы подали заявку
                    </Button>
                  )}
                  {userCancelledEvents?.includes(event.id) && (
                    <Button className="rejected" onClick={cancellButtonEvent}>
                      Заявка отклонена
                    </Button>
                  )}
                </div>
                <div className="buttons__item">
                  {!userFollowedEvents?.includes(event.id) ? (
                    <Button
                      onClick={followEvent}
                      className="follow"
                      loading={loading}
                    >
                      Следить
                    </Button>
                  ) : (
                    <Button
                      className="follow isFollowed"
                      onClick={cancelFollow}
                      loading={loading}
                    >
                      Вы подписаны
                    </Button>
                  )}
                </div>
              </div>
            </>
          )
          //период проведения
        } else if (isEventDay()) {
          return (
            <>
              {userEvents?.includes(event.id) && (
                <Button
                  className="participant"
                  onClick={cancelParticipate}
                  loading={loading}
                >
                  Вы участник
                </Button>
              )}
              {userModeratedEvents?.includes(event.id) && (
                <Button className="participant" onClick={cancelParticipate}>
                  Вы подали заявку
                </Button>
              )}
              {userCancelledEvents?.includes(event.id) && (
                <Button className="rejected" onClick={cancellButtonEvent}>
                  Заявка отклонена
                </Button>
              )}
            </>
          )
        } else if (isEventOver()) {
          if (
            !userReviews.includes(event.id) &&
            userEvents?.includes(event.id)
          ) {
            return (
              <Button
                onClick={modalIsOpen}
                className="follow"
                loading={loading}
              >
                Оставить отзыв
              </Button>
            )
          } else {
            return (
              <Button
                className="follow isFollowed"
                onClick={cancellButtonEvent}
              >
                Отзыв отправлен
              </Button>
            )
          }
          //любой другой день
        } else {
          return (
            <div className="buttons__wrapper">
              <div className="buttons__item">
                {userEvents?.includes(event.id) && (
                  <Button
                    className="participant"
                    onClick={cancelParticipate}
                    loading={loading}
                  >
                    Вы участник
                  </Button>
                )}
                {userModeratedEvents?.includes(event.id) && (
                  <Button className="participant" onClick={cancelParticipate}>
                    Вы подали заявку
                  </Button>
                )}
                {userCancelledEvents?.includes(event.id) && (
                  <Button className="rejected" onClick={cancellButtonEvent}>
                    Заявка отклонена
                  </Button>
                )}
              </div>
              <div className="buttons__item">
                {!userFollowedEvents?.includes(event.id) ? (
                  <Button
                    onClick={followEvent}
                    className="follow"
                    loading={loading}
                  >
                    Следить
                  </Button>
                ) : (
                  <Button
                    className="follow isFollowed"
                    onClick={cancelFollow}
                    loading={loading}
                  >
                    Вы подписаны
                  </Button>
                )}
              </div>
            </div>
          )
        }
      }
      // если мероприятие закрыто
      if (event.status === 3) {
        if (!userReviews.includes(event.id) && userEvents?.includes(event.id)) {
          return (
            <Button onClick={modalIsOpen} className="follow">
              Оставить отзыв
            </Button>
          )
        } else {
          return (
            <Button className="follow isFollowed" onClick={cancellButtonEvent}>
              Отзыв отправлен
            </Button>
          )
        }
      }
      if (event.status === 4) {
        return (
          <Button className="rejected" onClick={cancellButtonEvent}>
            Отменено
          </Button>
        )
      }
      if (event.status === 5) {
        return !userFollowedEvents?.includes(event.id) ? (
          <Button onClick={followEvent} className="follow" loading={loading}>
            Следить
          </Button>
        ) : (
          <Button
            className="follow isFollowed"
            onClick={cancelFollow}
            loading={loading}
          >
            Вы подписаны
          </Button>
        )
      }
    }
  }, [
    loading,
    userEvents,
    userCancelledEvents,
    userModeratedEvents,
    userFollowedEvents,
    userReviews,
  ])

  return <>{renderButtons()}</>
}

export default CustomButtonGroup
