import dayjs from 'dayjs'
import 'dayjs/locale/ru'

export function getDate(date) {
  return dayjs(date).locale('ru').format('DD.MM.YY ')
}

export function getDateWithTime(date) {
  return dayjs(date).locale('ru').format('DD.MM.YY HH:mm')
}
