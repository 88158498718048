export const DROPDOWN_KEYS = ['Период', 'Направление', 'Подразделение']
export const SUBDIVISION_KEYS = [
	'Военный учебный цент Южного федерального университета',
	'Ресурсный учебно-методический цент Южного федерального университета',
	'Научно-исследовательский институт робототехники и процессов управления',
	'Научно-исследовательский технологический Центр нейротехнологий',
	' Научно-исследовательский институт многопроцессорных вычислительных систем имени академика А.В.Каляева',
	'Экологическое объединение Южного федерального университета',
	'Ресурсный учебно-методический центр по обучению инвалидов и лиц с ограниченными возможностями здоровья',
	'Академия архитектуры и искусств',
	'Академия биологии и биотехнологии ЮФУ',
	'Академия психологии и педагогики Южного федерального университета',
	'Академия физической культуры и спорта',
	'Ботанический сад',
	'Институт туризма, сервиса и креативных индустрий',
	'Институт высоких технологий и пьезотехники',
	'Институт истории и международных отношений',
	'Институт компьютерных технологий и информационной безопасности',
	'Институт математики, механики и компьютерных наук им. И.И. Воровича',
	'Институт нанотехнологий, электроники и приборостроения',
	'Институт наук о Земле',
	'Институт радиотехнических систем и управления',
	'Институт социологии и регионоведения',
	'Институт управления в экономических, экологических и социальных системах',
	'Институт филологии, журналистики и межкультурной коммуникации',
	'Институт философии и социально-политических наук',
	'Колледж прикладного профессионального образования',
	'Лицей Южного федерального университета',
	'Международный институт междисциплинарного образования и иберо-американских исследований',
	'Международный исследовательский институт интеллектуальных материалов',
	'НИИ физики',
	'НИИ ФОХ',
	'Подготовительное отделение для иностранных обучающихся',
	'Региональный научный центр Российской академии образования в Южном федеральном округе (ЮРНЦРАО)',
	'Специализированный учебно-научный центр Южного федерального округа',
	'Факультет бизнеса "Капитаны"',
	'Факультет управления',
	'Факультет физический',
	'Факультет химический',
	'Факультет экономический',
	'Факультет юридический',
	'Филиал ЮФУ в г. Геленджике',
	'Филиал ЮФУ в г. Новошахтинске',
	'Не учащийся в ЮФУ',
	'Центр карьеры ЮФУ',
]
export const DIRECTION_KEYS = [
	'Научно-образовательное воспитание',
	'Гражданско-патриотическое',
	'Сохранение исторической памяти, формирование чувства уважения к памяти защитников Отечества и подвигам героев Отечества',
	'Спортивно-оздоровительное, популяризация здорового образа жизни',
	'Культурно-массовое (творческое)',
	'Студенческое самоуправление',
	'Волонтерство (развитие добровольческого движения)',
	'Профессионально-трудовое',
	'Профилактика зависимостей: алкогольной, наркотической, компьютерной зависимостей',
	'Противодействие экстремизму и терроризму',
	'Профилактика правонарушений',
	'Профилактика коррупционных напрушений',
	'Формирование базовых ценностей (правовые, этические знания)',
	'Духовно-нравственное',
	'Социальная защита обучающихся, оказание психологической поддержки и консультативной помощи',
	'Культурно-просветительское, эстетическое',
	'Экологическое',
	'Адаптация иностранных обучающихся',
	'Воспитательная работа в общежитиях',
	'Информационное воспитание',
	'Студенческая мобильность',
	'Студотряд',
	'Научная деятельность',
	'ДПО и оздоровительный отдых',
]
export const EVENT_KEYS = [
	'Направление:',
	'Цели:',
	'Подразделение:',
	'Период проведения',
	'Период регистрации',
	'Место проведения:',
]
export const TYPE_EVENT_KEY = [
	'Лекции',
	'Мастер-классы',
	'Круглый стол',
	'Конференция',
	'Форум',
	'Концерт',
	'Турнир',
	'Конкурс',
	'Фестиваль',
	'Чемпионат',
	'Семинар',
	'Дебаты',
	'Курсы',
	'Онлайн-мероприятие',
]

export const STATUS_KEY = [
	'Идет регистрация',
	'План',
	'Активное',
	'Закрыто',
	'Отменено',
	'Отложенно',
]
export const STATUS_COLOR = [
	'#0C9809',
	'green',
	'green',
	'red',
	'volcano',
	'geekblue',
]
export const STATUS_ONLINE_KEY = ['Онлайн', 'Оффлайн']
export const STATUS_ONLINE_COLOR = ['#0057AD', '#C4C4C4']
export const EVENT_TAGS = [
	'Идет регистрация',
	'Проводится',
	'Скоро начнется',
	'Закрыто',
	'Отменено',
	'Отложенно',
]
