import React from 'react'
import logo from '../../assets/images/logo-header.svg'
import './Loader.scss'

const Loader = () => (
  <div className="spinner-container">
    <img src={logo} className="spinner" />
  </div>
)

export default Loader
