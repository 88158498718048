import { Button } from 'antd'
import React, { useEffect } from 'react'
import './ModalItem.scss'
import { CloseOutlined } from '@ant-design/icons'

function ModalItem({ children, isOpen, onClose, customCloseButton }) {
  useEffect(() => {
    if (isOpen) {
      document.querySelector('html').style.overflowY = 'hidden'
    } else {
      document.querySelector('html').style.overflowY = 'scroll'
    }

    return () => {
      document.querySelector('html').style.overflowY = 'scroll'
    }
  }, [isOpen])

  if (!isOpen) {
    return null
  }
  return (
    <div>
      <div className="modal__container">
        <div className="modal__dialog">
          <div className="modal__content">
            <Button
              className="modal_closeButton"
              onClick={onClose}
              icon={<CloseOutlined />}
            />

            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalItem
