import React, { useContext, useMemo } from 'react'
import CustomButtonGroup from './CustomButtonGroup'
import { Button, Card, Tag, Typography } from 'antd'
import cardImage from '../../assets/images/logo-header.svg'
import '../../Variable.scss'
import {
  STATUS_COLOR,
  EVENT_TAGS,
  STATUS_ONLINE_COLOR,
  STATUS_ONLINE_KEY,
  TYPE_EVENT_KEY,
} from '../../constants/constans'
import { getDateWithTime } from '../../helpers/FromatDate'
import isBetween from 'dayjs/plugin/isBetween'
import dayjs from 'dayjs'
import { AuthContext } from '../../context/Auth'
import { useHistory } from 'react-router-dom'

dayjs.extend(isBetween)
const { Paragraph } = Typography

function CustomCard({ modalIsOpen, event }) {
  const { Meta } = Card
  const { userFaculty } = useContext(AuthContext)
  let history = useHistory()
  const [loading, setLoading] = React.useState(false)
  const isRegistrationPeriod = () => {
    return dayjs(new Date()).isBetween(
      new Date(event.registrationDateStart),
      new Date(event.registrationDateEnd),
      null,
      '[]'
    )
  }

  const isEventDay = () => {
    return dayjs(new Date()).isBetween(
      new Date(event.dateStart),
      new Date(event.dateEnd),
      null,
      '[]'
    )
  }

  const isEventOver = () => {
    return dayjs(new Date()) > dayjs(new Date(event.dateEnd))
  }
  const format = useMemo(() => (event.isOnline ? 0 : 1), [event.isOnline])

  const calculateEventStatus = () => {
    return event.status !== 2
      ? event.status
      : isRegistrationPeriod()
      ? 0
      : isEventDay()
      ? 1
      : isEventOver()
      ? 3
      : 2
  }

  const isLimitedSeats = () => {
    return (
      event.status === 2 &&
      event.withLimits &&
      event.candidateLimit > 0 &&
      !event.withFacultyLimits
    )
  }

  const isLimitedFacultySeats = () => {
    return (
      event.withLimits &&
      event.withFacultyLimits &&
      event.facultyLimits?.some((el) => el.faculty === userFaculty) &&
      event.facultyLimits?.findIndex((el) => el.faculty === userFaculty)
        .withLimits
    )
  }

  const pushToEvent = (e) => {
    if (e.target.type === 'button') {
      return false
    } else if (e.target.type === 'span') {
      return false
    } else {
      history.push(`/event-page/${event.id}`)
    }
  }

  return (
    <div key={event.id}>
      <Card
        hoverable
        onClick={pushToEvent}
        cover={
          <img
            className='cover__card'
            alt='image'
            src={
              event.image === null
                ? cardImage
                : `${process.env.REACT_APP_BASE_PATH}/uploads/${event.image?.path}`
            }
          />
        }
      >
        {isLimitedFacultySeats() && (
          <Tag className='card__places'>
            Мест:{' '}
            {
              event.facultyLimits?.find((el) => el.faculty === userFaculty)
                .emptySeats
            }
            /{event.facultyLimits?.find((el) => el.faculty === userFaculty).lim}
          </Tag>
        )}

        {isLimitedSeats() && (
          <Tag className='card__places'>
            Мест: {event.emptySeats}/{event.candidateLimit}
          </Tag>
        )}

        <div className='status__left_wrapper'>
          <Tag
            className='card__status_left'
            color={STATUS_ONLINE_COLOR[format]}
          >
            {STATUS_ONLINE_KEY[format]}
          </Tag>
        </div>

        <Tag
          className='card__status'
          color={STATUS_COLOR[calculateEventStatus()]}
        >
          {EVENT_TAGS[calculateEventStatus()]}
        </Tag>
        <Meta
          title={
            <Paragraph ellipsis={{ rows: 3, expandable: false }}>
              {event.name}
            </Paragraph>
          }
        />

        <div className='card__type_wrapper'>
          <div className='card__type'>{event.type ? event.type.name : ''}</div>
          <div className='card__type_date'>
            {getDateWithTime(event.dateStart)}
          </div>
        </div>
        <CustomButtonGroup
          modalIsOpen={modalIsOpen}
          event={event}
          loading={loading}
          setLoading={setLoading}
        />
      </Card>
    </div>
  )
}

export default CustomCard
