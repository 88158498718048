import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { checkIsAuthenticated, authLogin, authLogout } from '../api/auth'
import { useHistory } from 'react-router-dom'
import { eventsApi } from '../api/api'
import { useToasts } from 'react-toast-notifications'

export const AuthContext = React.createContext({})

export default function Auth({ children }) {
  const { addToast } = useToasts()
  const history = useHistory()
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.access_token
  )
  const [isLoading, setIsLoading] = useState(true)
  const [userInfo, setUserInfo] = useState(null)
  const [userEvents, setUserEvents] = useState([])
  const [userReviews, setUserReviews] = useState([])
  const [userModeratedEvents, setUserModerationEvents] = useState([])
  const [userCancelledEvents, setUserCancelledEvents] = useState([])
  const [userFollowedEvents, setuserFollowedEvents] = useState([])
  const [userFaculty, setUserFaculty] = useState('')
  // useEffect(() => {
  //   checkAuth()
  // }, [])

  const checkToken = () => {
    if (!localStorage.access_token) history.push('/login')
  }

  useEffect(() => {
    checkAuth()
    window.addEventListener('storage', () => {
      checkToken()
    })
    return () => {
      window.removeEventListener('storage', () => {
        checkToken()
      })
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      eventsApi
        .getMyEvents()
        .then(d => {
          if (d.data.items.length) {
            setUserEvents(
              d.data.items.filter(el => el.status === 1).map(el => el.event.id)
            )
            setUserModerationEvents(
              d.data.items.filter(el => el.status === 0).map(el => el.event.id)
            )
            setUserCancelledEvents(
              d.data.items.filter(el => el.status === 2).map(el => el.event.id)
            )
          }
        })
        .catch(e => {
          addToast('Ошибка при получение данных', { appearance: 'error' })
        })
      eventsApi.getMyReviews().then(d => {
        if (d.data.items.length) {
          setUserReviews(d.data.items.map(el => el.event.id))
        }
      })
    }
  }, [isAuthenticated])

  const checkAuth = async () => {
    if (localStorage.getItem('access_token')) {
      try {
        const userInfo = await checkIsAuthenticated()
        localStorage.setItem('userInfo', userInfo.fullName)
        localStorage.setItem('userId', userInfo.id)
        localStorage.setItem('ROLE', userInfo.roles[0])
        setuserFollowedEvents(userInfo.followedEvents?.map(el => el.id))
        if (!userInfo.studentInfo.length) {
          // setIsAuthenticated(false)
        }
        if (userInfo.studentInfo[0]?.faculty) {
          setUserFaculty(userInfo.studentInfo[0]?.faculty)
        } else {
          setUserFaculty('no')
        }
        setIsAuthenticated(true)
        setUserInfo(localStorage.getItem('userInfo'))
      } catch (e) {
        console.log(e)
        addToast('Ошибка при получение данных пользователя', {
          appearance: 'error',
        })
        localStorage.clear()
        setIsAuthenticated(false)
      }
    } else {
      setIsAuthenticated(false)
    }
  }

  const saveUserModeratedEvents = data => {
    setUserModerationEvents(oldArray => [...oldArray, data])
  }

  const saveUserFollowedEvents = data => {
    setuserFollowedEvents(oldArray => [...oldArray, data])
  }

  const saveUserEvents = data => {
    setUserEvents(oldArray => [...oldArray, data])
  }

  const handleRemoveParticipateEvent = data => {
    setUserEvents(userEvents.filter(item => item !== data))
  }

  const handleRemoveParticipateModeratedEvent = data => {
    setUserModerationEvents(userModeratedEvents.filter(item => item !== data))
  }

  const handleRemoveFollowingEvent = data => {
    setuserFollowedEvents(userFollowedEvents.filter(item => item !== data))
  }

  const saveReview = data => {
    setUserReviews(oldArray => [...oldArray, data])
  }

  const makeLogin = credentials => {
    setIsAuthenticated(true)
    setUserInfo(credentials.fullName)
  }

  const logout = () => {
    authLogout()
    setIsAuthenticated(false)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        makeLogin,
        logout,
        userInfo,
        saveUserEvents,
        saveReview,
        userEvents,
        userReviews,
        userCancelledEvents,
        userModeratedEvents,
        saveUserModeratedEvents,
        userFollowedEvents,
        saveUserFollowedEvents,
        userFaculty,
        setuserFollowedEvents,
        setUserFaculty,
        handleRemoveFollowingEvent,
        handleRemoveParticipateEvent,
        handleRemoveParticipateModeratedEvent,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

Auth.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
}
