import React from 'react'
import { ReactComponent as SubcribeIcon } from '../../assets/images/followIcon.svg'
import { ReactComponent as UnsubscribeIcon } from '../../assets/images/unsubscribeIcon.svg'
import { ReactComponent as ModerateIcon } from '../../assets/images/moderateIcon.svg'
import { ReactComponent as ParticipateIcon } from '../../assets/images/participateIcon.svg'
import { ReactComponent as ReviewIcon } from '../../assets/images/review.svg'
import { ReactComponent as ErrorIcon } from '../../assets/images/errorIcon.svg'
import { ReactComponent as LeaveEventIcon } from '../../assets/images/leaveEventIcon.svg'

import './Toast.scss'

const CustomToast = ({ appearance, children }) => {
  const renderToastIcon = () => {
    switch (appearance) {
      case 'subscribe':
        return <SubcribeIcon className="mytoast__icon" />
        break
      case 'unsubscribe':
        return <UnsubscribeIcon className="mytoast__icon" />
        break
      case 'moderation':
        return <ModerateIcon className="mytoast__icon" />
        break
      case 'participate':
        return <ParticipateIcon className="mytoast__icon" />
        break
      case 'review':
        return <ReviewIcon className="mytoast__icon" />
        break
      case 'leaveEvent':
        return <LeaveEventIcon className="mytoast__icon" />
        break
      case 'error':
        return <ErrorIcon className="mytoast__icon" />
        break
      default:
        break
    }
  }
  return (
    <div className="mytoast__wrapper">
      <div
        style={{
          background: appearance === 'error' ? '#F9EEEE' : '#EFF2F9',
          display: 'flex',
          justifyContent: 'flex-start',
        }}
        className="mytoast"
      >
        {renderToastIcon()}
        <div className="mytoast__content">{children}</div>
      </div>
    </div>
  )
}

export default CustomToast
