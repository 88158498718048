import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../../context/Auth'

const PrivateRoute = ({ children, ...otherProps }) => {
  const { isAuthenticated } = useContext(AuthContext)
  console.log(isAuthenticated)
  return (
    <Route
      {...otherProps}
      render={(props) =>
        isAuthenticated ? (
          React.cloneElement(children, props)
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

export default PrivateRoute
