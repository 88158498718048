import React from "react";
import Subtitle from "../labels/Subtitle"
import { Input } from "antd";
import '../../Variable.scss'

const { Search } = Input;

function CustomSearch({searchTerm, handleChange}) {

  return (
    <div className="search-wrapper">
      <Subtitle name={"Поиск"} />
      <Search className="search__element" value={searchTerm} placeholder="Поиск" onSearch={''} onChange={handleChange} />
    </div>
  );
}

export default CustomSearch;
