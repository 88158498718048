import React from "react";
import { Select } from 'antd';
import Subtitle from "../labels/Subtitle"
import '../../Variable.scss'


const { Option } = Select;

function CustomSelect({selectKey, options, handleChangeSelect, type, defaultValue}) {
  return (
    <div className="filtres-wrapper">
      <Subtitle name={selectKey}/>
      <Select
        onChange={(event) => handleChangeSelect(event, type)}
        showSearch
        defaultValue={defaultValue}
        allowClear={true}
        className="select__element"
        placeholder="Выберите"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
      >
        {options.map((option, index) => (
          <Option key={index} value={index}>{option}</Option>
        ))}
      </Select>
    </div>
  );
}

export default CustomSelect;
