import { Button } from 'antd'
import { eventsApi } from '../../../api/api'
import { AuthContext } from '../../../context/Auth'
import React, { useContext } from 'react'
import { useToasts } from 'react-toast-notifications'
import ModalLayout from '../ModalLayout'
import '../ModalItem.scss'

function ModalConsentToPersonalData({ closeModal, setLoading, event }) {
  const { saveUserEvents, saveUserModeratedEvents } = useContext(AuthContext)
  const { addToast } = useToasts()

  const takeParticipate = async e => {
    e.persist()
    e.stopPropagation()
    e.preventDefault()
    setLoading(true)
    try {
      await eventsApi.takeParticipate({
        event: `api/events/${event.id}`,
      })
      if (event.isModerationPossible) {
        saveUserModeratedEvents(event.id)
        addToast('Вашу кандидатуру рассмотрят в ближайшее время', {
          appearance: 'moderation',
        })
        setLoading(false)
        closeModal()
      } else {
        saveUserEvents(event.id)
        addToast('Вы зарегистрированы на мероприятие', {
          appearance: 'participate',
        })
        setLoading(false)
        closeModal()
      }
    } catch (e) {
      addToast('Ошибка при записи на мероприятие', { appearance: 'error' })
      setLoading(false)
      closeModal()
    }
  }

  return (
    <ModalLayout isOpen={true} onClose={closeModal}>
      <div className="modal__wrapper">
        <div className="modal__wrapper_container">
          <div className="modal__title">
            <br></br>
            <div className="title">
              Вы даете согласие на обработку персональных данных?
            </div>
          </div>
          <div className="buttons__wrap">
            <Button
              onClick={takeParticipate}
              className="buttonAccept"
              color="success"
            >
              Да
            </Button>
            <Button
              onClick={closeModal}
              className="buttonAccept red"
              color="cancel"
            >
              Нет
            </Button>
          </div>
        </div>
      </div>
    </ModalLayout>
  )
}

export default ModalConsentToPersonalData
