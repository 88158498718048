import React, { useState, useEffect, useContext, useRef } from 'react'
import Title from '../../components/labels/Title'
import { useLocation, useHistory } from 'react-router-dom'
import { Col, Row, Card, DatePicker, Button } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import CustomCard from '../../components/Custom/CustomCard'
import CustomSelect from '../../components/Custom/CustomSelect'
import CustomSearch from '../../components/Custom/CustomSearch'
import './AllEvents.scss'
import { Menu } from 'antd'
import clarityFilter from '../../assets/images/clarity_filter.svg'
import { eventsApi } from '../../api/api'
import {
  DROPDOWN_KEYS,
  STATUS_KEY,
  SUBDIVISION_KEYS,
  DIRECTION_KEYS,
} from '../../constants/constans'
import FollowModal from '../../layouts/ModalLayout/Modals/FollowModal'
import CommentModal from '../../layouts/ModalLayout/Modals/CommentModal'
import { useLazyLoading } from './useLazyLoading'
import FiltersModal from '../../layouts/ModalLayout/Modals/FiltersModal'
import SuccessModal from '../../layouts/ModalLayout/Modals/SuccessModal'
import YouFollowEventRegistration from '../../layouts/ModalLayout/Modals/YouFollowEventRegistration'
import UnderConsiderationModal from '../../layouts/ModalLayout/Modals/UnderConsiderationModal'
import { AuthContext } from '../../context/Auth'
import { checkIsAuthenticated } from '../../api/auth'
import { entries } from 'lodash'
import { useToasts } from 'react-toast-notifications'

const { RangePicker } = DatePicker

const items = [
  { key: '1', label: 'Все', path: '/all' },
  { key: '2', label: 'Предстоящие ', path: '/ada' },
  { key: '3', label: 'Завершенные', path: '/awdaw' },
]

function AllEvents({ pageType, title }) {
  const { addToast } = useToasts()
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [skeleton] = useState(
    new Array(10).fill(
      <Row className="site__skeleton_wrapper" gutter={[20, 20]}>
        <Col>
          <Card loading={loading} className="site__card_skeleton"></Card>
        </Col>
        <Col>
          <Card loading={loading} className="site__card_skeleton"></Card>
        </Col>
        <Col>
          <Card loading={loading} className="site__card_skeleton"></Card>
        </Col>
      </Row>
    )
  )

  const [visibleCommentModal, setVisibleCommentModal] = useState(false)
  const [visibleFiltersModal, setVisibleFiltersModal] = useState(false)

  const [eventId, setEventId] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [filterStatus, setFilterStatus] = useState([])
  const [filterSubdivision, setFilterSubdivision] = useState([])
  const [filterDivision, setFilterDivision] = useState([])
  const [page, setPage] = useState([2, 3, 4, 5])
  const [eventPeriod, setEventPeriod] = useState(Array(2))
  const location = useLocation()
  const [selectedKeys, setSelectedKey] = useState('1')
  const [currentPage, setCurrentPage] = useState(1)
  const [countOfPages, setCountOfPages] = useState(0)
  const [loadingMore, setLoadingMore] = useState(false)

  const toggleVisibleFiltresModal = () => {
    setVisibleFiltersModal(!visibleFiltersModal)
  }

  const handleChangeMenu = event => {
    setSelectedKey(event.key)
    switch (event.key) {
      case '1':
        setPage([2, 3, 4, 5])
        break
      case '2':
        setPage([2])
        break
      case '3':
        setPage([3])
        break
      default:
        setPage([2, 3, 4, 5])
    }
  }

  const handleChange = event => {
    setSearchTerm(event.target.value)
  }

  const handleChangeSelect = (event, type) => {
    switch (type) {
      case 'status':
        setFilterStatus(event)
        break
      case 'subdivision':
        setFilterSubdivision(SUBDIVISION_KEYS[event])
        break
      case 'direction':
        setFilterDivision(DIRECTION_KEYS[event])
        break
      case 'period':
        if (event) {
          setEventPeriod([
            new Date(new Date(event[0]._d).setHours(0, 0, 0, 0)),
            new Date(new Date(event[1]._d).setHours(23, 59, 59, 999)),
          ])
        } else {
          setEventPeriod(Array(2))
        }
        break
      default:
        break
    }
  }

  const showMore = async () => {
    setLoadingMore(true)
    if (pageType === 'all') {
      try {
        const response = await eventsApi.getEvents({
          name: searchTerm,
          direction: filterDivision,
          subdivision: filterSubdivision,
          status: page,
          page: currentPage + 1,
          'dateStart[after]': eventPeriod[0],
          'dateStart[before]': eventPeriod[1],
          ...(selectedKeys === '2' && { isRegistrationStarted: 0 }),
        })
        setCurrentPage(oldValue => oldValue + 1)
        setEvents(oldValue => [...oldValue.concat(response.data.items)])
      } catch (e) {
        setLoadingMore(false)
        addToast('Ошибка при получении мероприятий', { appearance: 'error' })
      }
    } else {
      try {
        const response = await eventsApi.getEvents({
          name: searchTerm,
          direction: filterDivision,
          subdivision: filterSubdivision,
          status: page,
          page: currentPage + 1,
          'dateStartcountOfPages[after]': eventPeriod[0],
          'dateStart[before]': eventPeriod[1],
          'participants.user.id': localStorage.getItem('userId'),
          ...(selectedKeys === '2' && { isRegistrationStarted: 0 }),
        })
        setCurrentPage(oldValue => oldValue + 1)
        setEvents(oldValue => [...oldValue.concat(response.data.items)])
      } catch (e) {
        setLoadingMore(false)
        addToast('Ошибка при получении мероприятий', { appearance: 'error' })
      }
    }
    setLoadingMore(false)
  }

  const showCommentModal = e => {
    e.persist()
    e.stopPropagation()
    e.preventDefault()
    setVisibleCommentModal(true)
  }

  useEffect(() => {
    ;(async function f() {
      setLoading(true)
      checkIsAuthenticated()
      if (pageType === 'all') {
        try {
          const response = await eventsApi.getEvents({
            name: searchTerm,
            direction: filterDivision,
            subdivision: filterSubdivision,
            status: page,
            'dateStart[after]': eventPeriod[0],
            'dateStart[before]': eventPeriod[1],
            ...(selectedKeys === '2' && { isRegistrationStarted: 0 }),
          })
          setCountOfPages(response.data.countOfPages)
          setEvents(response.data.items)
        } catch (e) {
          addToast('Ошибка при получении мероприятий', { appearance: 'error' })
        }
      } else {
        try {
          const response = await eventsApi.getEvents({
            name: searchTerm,
            direction: filterDivision,
            subdivision: filterSubdivision,
            status: page,
            'dateStart[after]': eventPeriod[0],
            'dateStart[before]': eventPeriod[1],
            'participants.user.id': localStorage.getItem('userId'),
            ...(selectedKeys === '2' && { isRegistrationStarted: 0 }),
          })
          setCountOfPages(response.data.countOfPages)
          setEvents(response.data.items)
        } catch (e) {
          addToast('Ошибка при получении мероприятий', { appearance: 'error' })
        }
      }
      setLoading(false)
    })()
  }, [
    searchTerm,
    filterDivision,
    filterSubdivision,
    filterStatus,
    page,
    location,
    eventPeriod,
  ])

  const noMoreEvents = () => {
    return currentPage < countOfPages
  }

  return (
    <>
      <div className="container">
        {}
        {visibleCommentModal && (
          <CommentModal
            eventId={eventId}
            toggleClose={() => setVisibleCommentModal(false)}
            closeModal={() => setVisibleCommentModal(false)}
          />
        )}
        {/* Модалка отзыва */}
        {visibleFiltersModal && (
          <FiltersModal
            handleChangeSelect={handleChangeSelect}
            closeModal={toggleVisibleFiltresModal}
            filterSubdivision={filterSubdivision}
            filterDivision={filterDivision}
            filterStatus={filterStatus}
          />
        )}
        {/* Фильтр модалка мобильная*/}
        <div className="all__events_title">
          <Title name={title} />
        </div>
        <div className="filter_icon">
          <img onClick={toggleVisibleFiltresModal} src={clarityFilter} />
        </div>
        <div className="search__select_container">
          <Col className="search__date_wrapper" span={8}>
            <Col span={24}>
              <CustomSearch
                handleChange={handleChange}
                searchTerm={searchTerm}
              />
            </Col>
          </Col>
          <div className="select__wrapper">
            <CustomSelect
              handleChangeSelect={handleChangeSelect}
              type={'direction'}
              options={DIRECTION_KEYS}
              selectKey={DROPDOWN_KEYS[1]}
            />
          </div>
          <div className="select__wrapper">
            <CustomSelect
              handleChangeSelect={handleChangeSelect}
              type={'subdivision'}
              options={SUBDIVISION_KEYS}
              selectKey={DROPDOWN_KEYS[2]}
            />
          </div>
          <div className="select__wrapper">
            <Col className="date__wrapper" span={24}>
              <div className="main__subtitle_el">{DROPDOWN_KEYS[0]}</div>
              <RangePicker
                onChange={e => handleChangeSelect(e, 'period')}
                className="search__element"
                locale={locale}
              />
            </Col>
          </div>
        </div>
        <Menu
          className="menu__wrapper"
          mode="horizontal"
          selectedKeys={[selectedKeys]}
          onClick={handleChangeMenu}
        >
          {items.map(item => (
            <Menu.Item key={item.key}>{item.label}</Menu.Item>
          ))}
        </Menu>
        <div className="site__card_container">
          {loading && <div>{skeleton}</div>}
          <div className="site__card_wrapper">
            {!loading && events && events.length > 0 ? (
              <>
                {events.map(event => (
                  <div className="site__card" key={event.id}>
                    <CustomCard
                      {...event}
                      pageType={pageType}
                      modalIsOpen={showCommentModal}
                      event={event}
                      key={event.id}
                    />
                  </div>
                ))}
              </>
            ) : (
              <div>Нет мероприятий</div>
            )}
          </div>
          {noMoreEvents() ? (
            <Button
              onClick={showMore}
              type="link"
              loading={loadingMore}
              style={{ marginTop: '20px', fontSize: '16px' }}
            >
              Показать еще
            </Button>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default AllEvents
