import request from './request'

const authData = {
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
}

const fullTokenUrl = process.env.REACT_APP_TOKEN_PATH

export function refreshToken(refresh_token) {
  const params = Object.assign(authData, {
    ...authData,
    grant_type: 'refresh_token',
    refresh_token,
  })
  try {
    return request({
      url: fullTokenUrl,
      method: 'get',
      params,
    })
  } catch {
    return Promise.reject()
  }
}
export function logout() {
  localStorage.clear()
  return Promise.reject()
}

export async function checkIsAuthenticated() {
  try {
    const response = await request({
      url: '/api/users/current',
      method: 'get',
    }).catch(() => logout())
    return response.data
  } catch (err) {
    return err
  }
}

export async function authLogin(credentials) {
  const params = Object.assign(authData, {
    grant_type: 'password',
    username: credentials.login,
    password: credentials.password,
  })
  try {
    const response = await request({
      url: fullTokenUrl,
      method: 'post',
      params,
    })
    // localStorage.setItem("ACCESS_TOKEN", response.data.access_token)
    return response
  } catch (e) {
    console.log(e)
    return false
  }
}

export function authLogout() {
  localStorage.clear()
  window.location.replace('/login')
}
