import react, { useEffect, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import axios from 'axios'
import { AuthContext } from '../../context/Auth'
import request from '../../api/request'
import Loader from '../../components/Loader/index'
const ThroughAuth = () => {
  const useQuery = () => new URLSearchParams(useLocation().search)
  const history = useHistory()
  const { makeLogin, setUserFaculty, setuserFollowedEvents } = useContext(
    AuthContext
  )
  let query = useQuery()

  useEffect(() => {
    const code = query.get('code')

    const fetchData = async () => {
      const params = {
        grant_type: 'urn:auth:external_token',
        external_service: 'microsoft',
        token: code,
        client_id: '1_535qdwaqro080c0g4ows0scoos8skc08s0wcg8ooookk4ssgww',
        client_secret: '2xy7ogpute2osgc8goow0gc04kc0sgs8wg4w8owwos04ww4w0',
      }
      axios
        .get(process.env.REACT_APP_TOKEN_PATH, {
          params: params,
        })
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem('access_token', response.data.access_token)
            localStorage.setItem('refresh_token', response.data.refresh_token)
          }
        })
        .then(async () => {
          const userinfo = await request({
            url: '/api/users/current',
            method: 'get',
          })
          localStorage.setItem('userInfo', userinfo.data.fullName)
          localStorage.setItem('userId', userinfo.data.id)
          localStorage.setItem('ROLE', userinfo.data.roles[0])

          setuserFollowedEvents(
            userinfo.data.followedEvents?.map((el) => el.id)
          )
          setUserFaculty(userinfo.data.studentInfo[0].faculty)
          makeLogin(userinfo.data)
        })
        .then(() => {
          redirectToHome()
        })
        .catch((error) => {
          console.log(error)
        })
    }

    fetchData()
  }, [])
  const redirectToHome = () => {
    history.push('/')
  }
  return (
    <>
      <Loader />
    </>
  )
}

export default ThroughAuth
