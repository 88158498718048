import React from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.scss"

function Navbar() {
  return (
    <div className="navlink__wrapper">
      <div className="nav__item">
        <NavLink activeClassName="nav__item__selected" exact to="/">Все мероприятия</NavLink>
      </div>
      <div className="nav__item">
        <NavLink activeClassName="nav__item__selected" to="/my-events">Мои мероприятия</NavLink>
      </div>
    </div>
  );
}

export default Navbar;
