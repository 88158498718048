import React, { useContext, useState } from 'react'
import { Typography, Space, Input, Button, Form } from 'antd'
import axios from 'axios'
import classes from './Login.module.css'
import { AuthContext } from '../../context/Auth'
import { useHistory } from 'react-router-dom'
import request from '../../api/request'
import { useToasts } from 'react-toast-notifications'
import logo from '../../assets/images/logo.svg'

const { Text, Link } = Typography

function LoginForm() {
  const history = useHistory()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)

  const { makeLogin, saveUserEvents, setuserFollowedEvents, setUserFaculty } =
    useContext(AuthContext)
  const [state, setState] = useState({
    username: '',
    password: '',
  })
  const handleChange = e => {
    const { id, value } = e.target
    setState(prevState => ({
      ...prevState,
      [id]: value,
    }))
  }
  const handleSubmitClick = e => {
    setLoading(true)
    const payload = {
      username: e.username,
      password: e.password,
      grant_type: 'password',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    }
    axios
      .get(process.env.REACT_APP_TOKEN_PATH, {
        params: payload,
      })
      .then(response => {
        if (response.status === 200) {
          setState(prevState => ({
            ...prevState,
          }))
          localStorage.setItem('access_token', response.data.access_token)
          localStorage.setItem('refresh_token', response.data.refresh_token)
        }
      })
      .then(async () => {
        const userinfo = await request({
          url: '/api/users/current',
          method: 'get',
        })
        localStorage.setItem('userInfo', userinfo.data.fullName)
        localStorage.setItem('userId', userinfo.data.id)
        localStorage.setItem('ROLE', userinfo.data.roles[0])

        setuserFollowedEvents(userinfo.data.followedEvents?.map(el => el.id))
        setUserFaculty(userinfo.data.studentInfo?.[0]?.faculty)
        makeLogin(userinfo.data)
      })
      .then(async () => {
        const userEvents = await request({
          url: `/api/users/${localStorage['userId']}/participant_events`,
          method: 'get',
        })
        saveUserEvents(userEvents.data.participantEvents)
      })
      .then(() => {
        setLoading(false)
        redirectToHome()
      })
      .catch(error => {
        setLoading(false)
        addToast('Неверный логин или пароль', { appearance: 'error' })
        console.log(error)
      })
  }
  const handleMicrosoftAuth = e => {
    e.preventDefault()
    window.location.href =
      'https://login.microsoftonline.com/19ba435d-e46c-436a-84f2-1b01e693e480/oauth2/v2.0/authorize?client_id=2f735c8d-f497-4876-8f75-5e7112d9cfcb&response_type=code&scope=https%3A%2F%2Fgraph.microsoft.com%2Fuser.read&redirect_uri=' +
      encodeURI(`https://event.sfedu.ru/through_auth`) +
      '&response_mode=query'
  }

  const redirectToHome = () => {
    history.push('/')
  }
  return (
    <Form
      className={classes.loginForm}
      name="loginForm"
      onFinish={handleSubmitClick}
      onFinishFailed={() =>
        addToast('Неверный логин или пароль', { appearance: 'error' })
      }
      requiredMark={false}
    >
      <img src={logo} alt="logo" className={classes.logo} />
      <Space align="center" direction="vertical">
        <div className={classes.fild_left}>
          <Text className={classes.label} type="secondary">
            Логин
          </Text>
        </div>
        <Form.Item
          style={{ width: '300px' }}
          id="username"
          name="username"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input className={classes.input} />
        </Form.Item>
        <div className={classes.fild_left}>
          <Text className={classes.label} type="secondary">
            Пароль
          </Text>
        </div>
        <Form.Item
          style={{ width: '300px', marginBottom: 0 }}
          id="password"
          name="password"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input
            type="password"
            className={classes.input}
            style={{ marginBottom: 0 }}
          />
        </Form.Item>
        <Link href="/reg">Нет аккаунта? Зарегистрироваться</Link>
        <Button
          htmlType="submit"
          className={classes.submit}
          type="primary"
          disabled={loading}
        >
          Войти
        </Button>
      </Space>
      <Space align="center" direction="vertical">
        <Button
          disabled={loading}
          type="primary"
          onClick={handleMicrosoftAuth}
          className={classes.submit}
        >
          Войти через Microsoft
        </Button>
      </Space>
    </Form>
  )
}

export default LoginForm
