import React, { useEffect, useContext, useState } from 'react'
import Title from '../../components/labels/Title'
import './EventsPage.scss'
import cardImage from '../../assets/images/logo-header.svg'
import { Tag, Button } from 'antd'
import parse from 'html-react-parser'
import { eventsApi } from '../../api/api'
import {
  STATUS_COLOR,
  EVENT_KEYS,
  EVENT_TAGS,
  STATUS_ONLINE_COLOR,
  STATUS_ONLINE_KEY,
  TYPE_EVENT_KEY,
} from '../../constants/constans'
import { getDateWithTime } from '../../helpers/FromatDate'
import CustomButtonGroup from '../../components/Custom/CustomButtonGroup'
import { AuthContext } from '../../context/Auth'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import CommentModal from '../../layouts/ModalLayout/Modals/CommentModal'
import ModalConsentToPersonalData from '../../layouts/ModalLayout/Modals/ModalConsentToPersonalData'
import DocumentsComponent from '../../components/Custom/DocumentsComponent.js'
import { LinkPreview } from '@dhaiwat10/react-link-preview'

dayjs.extend(isBetween)
function EventPage(props) {
  const [event, setEvent] = React.useState(null)
  const [visibleCommentModal, setVisibleCommentModal] = useState(false)
  const [visibleAcceptModal, setVisibleAcceptModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const { userFaculty } = useContext(AuthContext)

  useEffect(() => {
    async function f() {
      const response = await eventsApi.getEvent(props.match.params.id)
      setEvent(response.data)
    }
    f()
  }, [])

  const getFormat = () => {
    if (event.isOnline) {
      return 0
    } else {
      return 1
    }
  }

  const isRegistrationPeriod = () => {
    return dayjs(new Date()).isBetween(
      new Date(event.registrationDateStart),
      new Date(event.registrationDateEnd),
      null,
      '[]'
    )
  }

  const isEventDay = () => {
    return dayjs(new Date()).isBetween(
      new Date(event.dateStart),
      new Date(event.dateEnd),
      null,
      '[]'
    )
  }

  const isEventOver = () => {
    return dayjs(new Date()) > dayjs(new Date(event.dateEnd))
  }

  const calculateEventStatus = () => {
    return event.status !== 2
      ? event.status
      : isRegistrationPeriod()
      ? 0
      : isEventDay()
      ? 1
      : isEventOver()
      ? 3
      : 2
  }

  const isLimitedSeats = () => {
    return (
      event.status === 2 &&
      event.withLimits &&
      event.candidateLimit > 0 &&
      !event.withFacultyLimits
    )
  }

  const isLimitedFacultySeats = () => {
    return (
      event.withLimits &&
      event.withFacultyLimits &&
      event.facultyLimits?.some((el) => el.faculty === userFaculty) &&
      event.facultyLimits?.findIndex((el) => el.faculty === userFaculty)
        .withLimits
    )
  }

  return (
    <>
      {event ? (
        <div className='event__container'>
          {visibleCommentModal && (
            <CommentModal
              eventId={event.id}
              closeModal={() => setVisibleCommentModal(false)}
            />
          )}
          {visibleAcceptModal && (
            <ModalConsentToPersonalData
              event={event}
              setLoading={setLoading}
              closeModal={() => setVisibleAcceptModal((prev) => !prev)}
            />
          )}
          <div className='event__photo'>
            <img
              alt='image'
              src={
                event.image == null
                  ? cardImage
                  : `${process.env.REACT_APP_BASE_PATH}/uploads/${event.image?.path}`
              }
            />
          </div>
          <div className='event__status_block'>
            <div className='event__title'>
              <Title name={event.name} />
            </div>
            <div className='event__status_item'>
              {isLimitedFacultySeats() && (
                <Tag>
                  Мест:{' '}
                  {
                    event.facultyLimits?.find(
                      (el) => el.faculty === userFaculty
                    ).emptySeats
                  }
                  /
                  {
                    event.facultyLimits?.find(
                      (el) => el.faculty === userFaculty
                    ).lim
                  }
                </Tag>
              )}
              {isLimitedSeats() && (
                <Tag>
                  Мест: {event.emptySeats}/{event.candidateLimit}
                </Tag>
              )}
              <Tag color={STATUS_COLOR[calculateEventStatus()]}>
                {EVENT_TAGS[calculateEventStatus()]}
              </Tag>
            </div>
          </div>
          <div className='event__type_block'>
            {event.type ? event.type.name : ''}
            <span className='format__online'>
              <Tag
                className='card__status_left'
                color={STATUS_ONLINE_COLOR[getFormat()]}
              >
                {STATUS_ONLINE_KEY[getFormat()]}
              </Tag>
            </span>
          </div>
          <div className='event__button_block'>
            <CustomButtonGroup
              modalAcceptIsOpen={() => setVisibleAcceptModal((prev) => !prev)}
              {...event}
              event={event}
              setLoading={setLoading}
              loading={loading}
              modalIsOpen={() => setVisibleCommentModal(true)}
            />
          </div>
          <div className='event__description_block'>
            {parse(event.description)}
          </div>
          <DocumentsComponent docs={event.docs} />

          {!!event.experts.length && (
            <div className='experts__block'>
              <div className='title'>Приглашенные эксперты/гости:</div>
              {event?.experts?.map((i) => (
                <div className='card__experts'>
                  <div className='title_name'>
                    {i.fio} <span>{i.post}</span>
                  </div>
                  <div className='card__format'>
                    Формат участия: <span>{i.format}</span>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className='event__keys_block'>
            <div>
              <span>{EVENT_KEYS[0]}</span>
            </div>
            <div>{event.direction}</div>
          </div>
          <div className='event__keys_block'>
            <div>
              <span>{EVENT_KEYS[1]}</span>
            </div>
            <div>{event.target}</div>
          </div>
          <div className='event__keys_block'>
            <div>
              <span>{EVENT_KEYS[2]}</span>
            </div>
            <div>{event.structure}</div>
          </div>
          <div className='event__keys_block'>
            <div>
              <span>{EVENT_KEYS[5]}</span>
            </div>
            <div>{event.place}</div>
          </div>
          <div className='event__period_block'>
            <div className='event__period_wrapper'>
              <div className='event__period_name'>{EVENT_KEYS[3]}:&nbsp;</div>
              <div>
                {' '}
                {getDateWithTime(event.dateStart)} -{' '}
                {getDateWithTime(event.dateEnd)}
              </div>
            </div>
            <div className='event__period_wrapper'>
              <div className='event__period_name'>{EVENT_KEYS[4]}:&nbsp;</div>
              <div className='event__period_name'>
                {getDateWithTime(event.dateCreate)} -{' '}
                {getDateWithTime(event.dateUpdate)}
              </div>
            </div>
            {event.media && (
              <div className='smi'>
                <div className='main__title'>Что пишут в СМИ</div>
                {event.media.map(({ link }, index) => (
                  <LinkPreview
                    style={{ marginBottom: '20px' }}
                    width={'100%'}
                    className='cardSmi'
                    key={index}
                    url={link}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default EventPage
