import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import MainLayout from './layouts/MainLayout/MainLayout'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import AllEvents from './pages/AllEventsPage/AllEvents'
import EventPage from './pages/EventPage/EventPage'
import PrivateRoute from './components/PrivateRouter/PrivateRouter'
import Auth from './context/Auth'
import LoginForm from './pages/LoginPage/Login'
import ThroughAuth from './pages/MicrosoftAuth/ThroughAuth'
import { ToastProvider } from 'react-toast-notifications'
import CustomToast from './components/Custom/CustomToast'
import RegistrationForm from './pages/RegistrationPage/Registration'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastProvider autoDismiss={true} components={{ Toast: CustomToast }}>
        <Auth>
          <Switch>
            <Route path="/login" render={() => <LoginForm />} />
            <Route path="/reg" render={() => <RegistrationForm />} />
            <Route path="/through_auth" render={() => <ThroughAuth />} />
            <MainLayout onMain={true}>
              {/* <Route path="/" render={() => <Redirect to={'/all-events'} />} />{' '} */}
              <PrivateRoute exact path="/">
                <AllEvents title={'Все мероприятия'} pageType={'all'} />
              </PrivateRoute>
              <PrivateRoute path="/event-page/:id">
                <EventPage />
              </PrivateRoute>
              <PrivateRoute exact path="/my-events">
                <AllEvents title={'Мои мероприятия'} pageType={'my'} />
              </PrivateRoute>
            </MainLayout>
          </Switch>
        </Auth>
      </ToastProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
