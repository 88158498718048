import React, { useState, useRef, useContext } from 'react'
import ModalLayout from '../ModalLayout'
import '../ModalItem.scss'
import { Button } from 'antd'
import star from '../../../assets/images/star.svg'
import { eventsApi } from '../../../api/api'
import { AuthContext } from '../../../context/Auth'
import { useToasts } from 'react-toast-notifications'

function CommentModal({ toggleClose, eventId, closeModal }) {
  const [rating, setRating] = useState(5)
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const { saveReview } = useContext(AuthContext)
  const { addToast } = useToasts()

  const handleRatingChange = (value) => {
    setRating(value)
  }

  const handleChange = (e) => {
    setText(e.target.value)
  }

  const sendReview = async () => {
    setLoading(true)
    try {
      await eventsApi.sendReview({
        text: text,
        rating: rating,
        event: `api/events/${eventId}`,
      })
      saveReview(eventId)
      addToast('Ваш отзыв отправлен!', {
        appearance: 'review',
      })
      setLoading(false)
    } catch {
      addToast('Ошибка при отправке отзыва', {
        appearance: 'error',
      })
      setLoading(false)
    }
    closeModal()
  }

  return (
    <div>
      <ModalLayout isOpen={true} onClose={closeModal}>
        <div className="modal__wrapper">
          <div className="modal__wrapper_container">
            <div className="modal__title">Оставить отзыв</div>
            <StarRatingDemo
              rating={rating}
              handleRatingChange={handleRatingChange}
            />
            <div className="modal__suptitle">Текст комментария</div>
            <div className="modal__input">
              <textarea value={text} onChange={handleChange}></textarea>
            </div>
            <div className="modal__button_block">
              <Button
                onClick={sendReview}
                className="modal__button"
                loading={loading}
              >
                Готово
              </Button>
            </div>
          </div>
        </div>
      </ModalLayout>
    </div>
  )
}

export function StarRating({
  count,
  value,
  inactiveColor = '0.1',
  activeColor = '0.9',
  onChange,
  visible,
}) {
  const stars = Array.from({ length: count }, () => '🟊')
  const handleChange = (value) => {
    onChange(value + 1)
  }

  return (
    <div>
      {stars.map((s, index) => {
        let style = inactiveColor
        if (index < value) {
          style = activeColor
        }
        return (
          <>
            <img
              className="imageStar__container"
              src={star}
              style={{ opacity: style }}
              key={index}
              onClick={() => handleChange(index)}
            />
          </>
        )
      })}
      {/* {value} */}
    </div>
  )
}

export function StarRatingDemo({ rating, handleRatingChange }) {
  // const [rating, setRating] = useState(3)

  return (
    <div className="modal__stars_container">
      <div className="modal__stars_title">Оценка</div>
      <StarRating
        activeOpacityImg={''}
        visible="0.5"
        count={5}
        size={10}
        value={rating}
        onChange={handleRatingChange}
      />
    </div>
  )
}

export default CommentModal
